exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-team-js": () => import("./../../../src/pages/our-team.js" /* webpackChunkName: "component---src-pages-our-team-js" */),
  "component---src-templates-dentists-dentist-js": () => import("./../../../src/templates/dentists/dentist.js" /* webpackChunkName: "component---src-templates-dentists-dentist-js" */),
  "component---src-templates-pages-page-js": () => import("./../../../src/templates/pages/page.js" /* webpackChunkName: "component---src-templates-pages-page-js" */),
  "component---src-templates-portfolio-category-portfolio-category-js": () => import("./../../../src/templates/portfolioCategory/portfolioCategory.js" /* webpackChunkName: "component---src-templates-portfolio-category-portfolio-category-js" */),
  "component---src-templates-portfolio-portfolio-js": () => import("./../../../src/templates/portfolio/portfolio.js" /* webpackChunkName: "component---src-templates-portfolio-portfolio-js" */),
  "component---src-templates-posts-post-js": () => import("./../../../src/templates/posts/post.js" /* webpackChunkName: "component---src-templates-posts-post-js" */),
  "component---src-templates-posts-posts-js": () => import("./../../../src/templates/posts/posts.js" /* webpackChunkName: "component---src-templates-posts-posts-js" */),
  "component---src-templates-services-service-js": () => import("./../../../src/templates/services/service.js" /* webpackChunkName: "component---src-templates-services-service-js" */)
}

